import React, { useEffect } from 'react';
 
const PopupOverlay = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('stickyScroll');
    } else {
      document.body.classList.remove('stickyScroll');
    }

    // Cleanup-funktion som körs när komponenten avmonteras eller isOpen ändras
    return () => {
      document.body.classList.remove('stickyScroll');
    };
  }, [isOpen]);  // Beroendet som useEffect kommer reagera på

  if (!isOpen) return null;
  
  return (
    <div className="popup-overlay" onClick={onClose}>
      
      <div onClick={e => e.stopPropagation()}>
        {children}
      </div>

      <div>
        <span className='fixed top-6 right-4 cross-closer box-shadow' onClick={onClose} style={{ zIndex: "9999999"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><line data-v-91f9f1d0="" x1="18" y1="6" x2="6" y2="18"></line> <line data-v-91f9f1d0="" x1="6" y1="6" x2="18" y2="18"></line></svg>
        </span>
      </div>
    </div>
  );
};

export default PopupOverlay;

// THis is how we create a PopupOverlay in React
// Visa popupen DIREKT, sen ladda in datan.
// 
// <PopupOverlay isOpen={isOpen} onClose={() => setIsOpen(false)}>
//  Här är "innehållet" eller popupen
// </PopupOverlay>
//